import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "page-header" }
const _hoisted_3 = { class: "page-header__name" }
const _hoisted_4 = {
  key: 0,
  class: "ml-auto right"
}
const _hoisted_5 = {
  key: 0,
  class: "toolbar"
}
const _hoisted_6 = { class: "page-content" }
const _hoisted_7 = { class: "row m-0" }
const _hoisted_8 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_source_component = _resolveComponent("select-source-component")!
  const _component_tirscript3_button = _resolveComponent("tirscript3-button")!
  const _component_tirscript3_loading = _resolveComponent("tirscript3-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.name), 1)
      ]),
      (_ctx.selectSource)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_select_source_component, {
              class: "mr-2",
              onOnChangeSource: _ctx.changeSource
            }, null, 8, ["onOnChangeSource"]),
            _createVNode(_component_tirscript3_button, {
              class: "active page-header__bnts",
              onOnClick: _ctx.changeFilter
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Отфильтровать ")
              ]),
              _: 1
            }, 8, ["onOnClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.$slots.toolbar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "toolbar")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_tirscript3_loading, { loading: _ctx.isLoading }, null, 8, ["loading"]),
          _renderSlot(_ctx.$slots, "default", {
            sourceLogId: _ctx.sourceLogId,
            filterChange: _ctx.filterChange
          })
        ])
      ])
    ])
  ]))
}